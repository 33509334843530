import React, { useEffect } from 'react'
import { useProducts } from '../../../hooks/useProducts'

function New_arrivals() {
  const { products, dispatch } = useProducts()
  const apiUrl = process.env.REACT_APP_API_URL || 'https://new-xp-back.onrender.com';
  console.log(apiUrl)

  useEffect(() => {
    const GetProducts = async () => {
      try {

        const res = await fetch(`${apiUrl}/api/products/all`, {
          method: "Get",
          credentials: "include"
          // headers: {
          //   'Content-Type': 'application/json',
          // },
        })
        if (!res.ok) {
          console.log("Network was not ok")
        }
        const data = await res.json()
        dispatch({ type: "Getproduct", payload: data })
      }
      catch (error) {

        console.log("error: ", error)
      }
    }
    GetProducts()
  }, [dispatch])
  return (
    <div className='new_arrivals_cont'>
      <hr />
      <h1>NEW Arrivals</h1>
      <div className='New_arrivals_products_cont'>
        {products
          .filter(val => val.n === 1)
          .map((val) => (
            <div key={val._id} className="nwr_prod">
              <div>
                <img src={val.src} alt={val.name} loading="lazy" />
              </div>
              <div className="details">
                <div className="line"></div>
                <div className="p-5">
                  <p className="text-center font-bold">{val.name}</p>
                  <p className="text-center text-wrap whitespace-normal break-words font-mono text-xs">
                    <code>{val.description}</code>
                  </p>
                  <p className="Price text-center mt-5 border border-purple-500 bg-purple-600 font-bold">
                    {val.price}DH
                  </p>
                </div>
              </div>
              <div className='overlay_button'>
                <button>BUY</button>
                <button>ADD</button>
              </div>
            </div>
          ))}

      </div>
    </div>
  )
}

export default New_arrivals
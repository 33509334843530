import React from 'react';
import { useInView } from "react-intersection-observer";
// import Titleboard from '../component/Titlebroad';
import Swipper from '../component/Swipper';
import Center from "./../pic/hg.png"
import Noise from "../pic/noise_giphy.gif"
import Fdiv from '../component/home/f_div/F_div';
function Home() {
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.2 // Adjust the threshold as needed
    });
    return (
        <div className='Home'>
            {/* <div className='Home_Background'></div> */}
            <div className='Home_Content'>
                {/* <div>HELLO HOME</div> */}
                {/* <Titleboard text="XPHENIX * XPHENIX " /> */}
                <div className='F_home_intro'>
                    <div className='Carouselsec'>
                        <div className=' containe_container '>
                            <div className={`Carousel_cont ${inView ? "slide-in" : ""}`} ref={ref}>
                                <img className='bg' src={Center} alt="Background" />
                                <Swipper className="absolute" />
                            </div>
                        </div>
                    </div>
                    <div className='f_dv'>
                        <Fdiv/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;

import React, { useState, useEffect } from 'react';
import Logo from "./../pic/LG.png";
import close from "./../pic/close_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg";
import { UseSendpasscode } from '../hooks/UseSendpasscode';
import { useCheckpasscode, Welcominemail } from '../hooks/usecheckpasscode';
function Entryform() {
    const [passcodeFormVisible, setPasscodeFormVisible] = useState(false);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [color, setColor] = useState("");
    const [passcode, setPassword] = useState("");
    const [errore, setError] = useState("");
    const [ intro, setIntro ] = useState(true)
    const { loadin, message, Send } = UseSendpasscode()
    const { incorect, error, checkpasscode } = useCheckpasscode()
    const {errorWelcomin ,Welcome} = Welcominemail()
    
    
    const togglePasscodeForm = () => {
        setPasscodeFormVisible(!passcodeFormVisible);
    };
    const HandleWelocmin = async(e)=>{
        e.preventDefault()
        const pass = sessionStorage.getItem("passcode")
        try{
            Welcome(pass,email)
            setEmail("")
            sessionStorage.removeItem("passcode")
        }
        catch(error){
            errorWelcomin(error)
        }
    }
    const handlepasscode = async (e) => {
        e.preventDefault()
        if (!passcode) {
            alert("You must enter the passcode")
            return;
        }
        try {
            checkpasscode(passcode)
            setPassword("")
            setIntro(false)
        }
        catch (error) {
            console.log(error)
        }
    }
    const handleSend = async (e) => {
        e.preventDefault();
        // Form validation logic
        if (!email || !username || !gender || !color) {
            alert("All fields are required!");
            return;
        }

        try {
            // Trigger the send function and await the result
            await Send(username, email, gender, color);
            setEmail("")
            setUsername("")
            setGender("")
            setColor("")

        } catch (err) {
            // You can customize the error handling here
            console.error("Failed to fetch:", err);
            setError("Something went wrong while sending data. Please try again later.");
            setEmail("")
            setUsername("")
            setGender("")
            setColor("")
            // Optionally set a custom error message
        }
    };
    // Automatically clear the error message after 10 seconds
    useEffect(() => {
        if (errore) {
            const timer = setTimeout(() => {
                setError(""); // Clear the error after 10 seconds
            }, 10000); // 10 seconds

            return () => clearTimeout(timer); // Cleanup timeout when the component is unmounted or errore changes
        }
    }, [errore]);
    return (
        <>
            {
                intro && (
                    <div className="entry-form">
                        <div className="logo_container">
                            <img src={Logo} alt="logo" className="logo" />
                        </div>
                        <div>
                            <p className='quotes'><q>What is to Give Light Must Endure Burning</q></p>
                        </div>
                        <div>
                            {errore && <div className="error-message"><p>{errore}</p></div>}
                            {message && <div className='passcode_message'><p>{message}</p></div>}
                        </div>
                        <div className='allform_cont'>
                            {!passcodeFormVisible && (
                                <form className="passcode_form" onSubmit={handlepasscode}>
                                    <input
                                        type="password"
                                        placeholder='Passcode'
                                        value={passcode}
                                        onChange={(e) => setPassword(e.target.value)}
                                        aria-label="Password Input"
                                    />
                                    <button type="submit">ENTER</button>
                                </form>
                            )}
                            {!passcodeFormVisible ?
                                <button className="join_waitlist" onClick={togglePasscodeForm}>
                                    Get The Passcode
                                </button>
                                :
                                <button className="join_waitlist" onClick={togglePasscodeForm}>
                                    <img src={close} alt="close" />
                                </button>
                            }
                            <div className={`user_info_form ${passcodeFormVisible ? 'open' : 'close'}`}>
                                {passcodeFormVisible && (
                                    <form onSubmit={handleSend}>
                                        <input
                                            type='email'
                                            placeholder='E-Mail'
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            required
                                            aria-label="Email Input"
                                        />
                                        <input
                                            type='text'
                                            placeholder='Username'
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                            required
                                            aria-label="Username Input"
                                        />
                                        <div className="gender_selection">
                                            <label>
                                                <input
                                                    type='radio'
                                                    value="Men"
                                                    checked={gender === "Men"}
                                                    onChange={e => setGender(e.target.value)}
                                                    required
                                                    aria-label="Gender Male"
                                                />
                                                <span>Men</span>
                                            </label>
                                            <label>
                                                <input
                                                    type='radio'
                                                    value="Women"
                                                    checked={gender === "Women"}
                                                    onChange={e => setGender(e.target.value)}
                                                    required
                                                    aria-label="Gender Female"
                                                />
                                                <span>Women</span>
                                            </label>
                                        </div>
                                        <p className='choosecolor_para'>*Select a color:</p>
                                        <div className="color-selection">
                                            {['red', 'purple', 'black', 'green'].map((col) => (
                                                <div
                                                    key={col}
                                                    className={`color-box ${col}`}
                                                    onClick={() => setColor(col)}
                                                    style={{ backgroundColor: col }}
                                                    role="button"
                                                    aria-label={`Select color ${col}`}
                                                    tabIndex={0}
                                                >
                                                    {color === col && <span>✔</span>}
                                                </div>
                                            ))}
                                        </div>
                                        <button className="submit-btn" type="submit">Submit</button>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>

                )
            }
            {
                !intro && (
                    <div className="entry-form">
                        <div className="logo_container">
                            <img src={Logo} alt="logo" className="logo" />
                        </div>
                        <div>
                            <p className='quotes'><q>What is to Give Light Must Endure Burning</q></p>
                        </div>
                        <form className="passcode_form" onSubmit={HandleWelocmin}>
                            <input
                                type="email"
                                placeholder='Email..'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                // aria-label=""
                            />
                            <button type="submit">ENTER</button>
                        </form>
                    </div>
                )
            }
        </>
    );
}

export default Entryform;

import { useState, useEffect } from "react"

export const UseSendpasscode = () => {
    // const [error, setError] = useState(false)
    const [loadin, setLoadin] = useState(false)
    const [message, setMessage] = useState("")
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage(""); // Clear the error after 10 seconds
            }, 10000); // 10 seconds

            return () => clearTimeout(timer); // Cleanup timeout when the component is unmounted or errore changes
        }
    }, [message]);

    const Link = process.env.REACT_APP_LINK || "https://new-xp-back.onrender.com"

    const Send = async (username, email, gender, color) => {
        setLoadin(true)
        const User = { username, email, gender, color }
        const res = await fetch(Link + "/user/sendentryverify", {
            method: "POST",
            headers: { "Content-type": "application/json" },
            credentials: 'include', // For cookies if required
            body: JSON.stringify(User)
        })
        if (!res.ok) {
            setLoadin(false)
            console.log(Link)
            // setError("EROOR")
        }

        if (res.ok) {
            console.log(Link)
            const data = await res.json()
            console.log(data)
            // setError(false)
            setLoadin(false)
            setMessage(`We SENT THE PASSCODE TO : ${email}`)
        }
    }

    return {loadin, message, Send }
}
import { createContext, useReducer } from "react"

export const ProductsContext = createContext()
const productreduc = (state, action) => {
    switch (action.type) {
        case "Getproduct":
            return {
                ...state,
                products: action.payload
            };
        case "GETID":
            const updatedProducts = [...state.products];
            const productIndex = updatedProducts.findIndex(p => p._id === action.payload._id);
            if (productIndex !== -1) {
                updatedProducts[productIndex] = action.payload;
            } else {
                updatedProducts.push(action.payload);
            }
            return {
                ...state,
                products: updatedProducts
            };
        case "Del":
            return {
                products: state.products.filter((t) => t._id !== action.payload._id)
            };
        default:
            return state;
    }
};


export const ProductsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(productreduc, {
        products: []
    })
    return (
        <ProductsContext.Provider value={{ ...state, dispatch }}>
            {children}
        </ProductsContext.Provider>
    )
}
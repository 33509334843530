import React from 'react'
import { useState , useEffect } from 'react';
import Navmax from './Nav/Navmax';
import Navmin from './Nav/Navmin';
function Nav() {
    const [media, setMedia] = useState(false);

    useEffect(() => {
        console.log("useefff 1 at nav  :")
        const handleMediaQuery = () => {
            setMedia(window.matchMedia("(max-width:820px)").matches);
        };
        window.addEventListener("resize", handleMediaQuery);
        handleMediaQuery();
        // window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener("resize", handleMediaQuery);
        };
    }, []);

    return (
        <div>
            {media ? <Navmax /> : <Navmin />}
        </div>

    )
}

export default Nav
import React from 'react'
import TypingText from './Typingtext'
import New_arrivals from './New_arrivals'

function F_div() {
    return (
        <div className='f_div_cont'>
            <div className='TypingText_container'>
                <TypingText style="Typintext" text="Through the flames of adversity, our true strength is forged." />
            </div>
            <New_arrivals />
        </div>
    )
}

export default F_div
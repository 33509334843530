import React from 'react'

function Titleboard(props) {
    return (
        <div className='Titleboeard'>
            <span className='ttl'>{props.text}</span>
        </div>
    )
}

export default Titleboard
import React, { useState } from 'react';

function Navmin() {
  const [dropmenu, setDropmenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  return (
    <div className="Navmin_cont">
      <div className="logo">X-PHENIX</div>
      <div className="menu">
        <div>SHOP</div>
        <div>EVENTS</div>
        <div
          className="relative"
          onMouseEnter={() => setDropmenu(true)}
          onMouseLeave={() => setDropmenu(false)}
        >
          BRAND
          {dropmenu && (
            <div className="drop">
              <p>About Us</p>
              <p>Our Vision</p>
              <p>Sustainability</p>
            </div>
          )}
        </div>
      </div>
      <div className="actions">
        <div onClick={() => setShowSearch(!showSearch)}>SEARCH</div>
        <div>LOGIN</div>
        <div>CART</div>
      </div>
      <div
        className={`search-bar ${
          showSearch ? "slide-down" : "slide-up"
        }`}
      >
        <input
          type="text"
          placeholder="Search..."
          className="search-input"
        />
      </div>
    </div>
  );
}

export default Navmin;

import './App.css';
// import Entryform from './pages/Entryform';
import Switching from './component/Switching';
function App() {
  return (
    <div className="App">
      {/* <Entryform/> */}
      <Switching/>
    </div>
  );
}

export default App;

import React, { useState } from 'react';

function Navmax() {
  const [menuOpen, setMenuOpen] = useState(false); // State for the burger menu
  const [brandOpen, setBrandOpen] = useState(false); // State for the "Brand" dropdown
  const [searchOpen, setSearchOpen] = useState(false); // State for the search bar

  return (
    <div className="Navmax">
      {/* Navigation */}
      <div className="nav-container">
        <div className="burger_menu_cont" onClick={() => setMenuOpen(!menuOpen)}>
          <div className="Burgermenu">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="Logo_max">
          <p>X-PHENIX</p>
        </div>

        <div className="Otherlinks">
          <span onClick={() => setSearchOpen(!searchOpen)}>Search</span>
          <span>Store</span>
        </div>
      </div>

      {/* Dropdown Menu */}
      {menuOpen && (
        <div className="max_drop_menu">
          <p className='cp'> &copy;XPHENIX </p>
          <p className='close' onClick={() => setMenuOpen(!menuOpen)}>X</p>
          <div className='rs'>
            <p>INsta</p>
            <p>INsta</p>
            <p>INsta</p>
          </div>
          <p>Store</p>
          <div>
            <p onClick={() => setBrandOpen(!brandOpen)}>Brand</p>
            {brandOpen && (
              <div className="Drop_menu_brand">
                <p>&gt;
                  About Us</p>
                <p>&gt;
                  Our Vision</p>
                <p>&gt;
                  Sustainability</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Search Bar */}
      {searchOpen && (
        <div className="search-bar">
          <input
            type="text"
            className="search-input"
            placeholder="Type to search..."
          />
        </div>
      )}
    </div>
  );
}

export default Navmax;

import React, { useState, useEffect } from "react";

const TypingText = ({style, text, speed = 100, pause = 1000 }) => {
    const [displayText, setDisplayText] = useState("");
    const [index, setIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        let timeout;
        if (isDeleting) {
            timeout = setTimeout(() => {
                setDisplayText(text.slice(0, index - 1));
                setIndex((prev) => prev - 1);
            }, speed / 2);
        } else {
            timeout = setTimeout(() => {
                setDisplayText(text.slice(0, index + 1));
                setIndex((prev) => prev + 1);
            }, speed);
        }

        if (!isDeleting && index === text.length) {
            // Pause before deleting
            timeout = setTimeout(() => setIsDeleting(true), pause);
        }

        if (isDeleting && index === 0) {
            // Pause before typing again
            timeout = setTimeout(() => setIsDeleting(false), pause);
        }

        return () => clearTimeout(timeout);
    }, [index, isDeleting, text, speed, pause]);

    return <span className={style}>{displayText}</span>;
};

export default TypingText;

import React from 'react';
import l from "../pic/hd1.png";
import m from "../pic/hd2.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

// Import required modules
import { Autoplay } from 'swiper/modules';

export default function Swipper() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                allowTouchMove={false} // Disable manual swiping
                modules={[Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="slide-content">
                        <img src={l} alt="Slide 1" className="slide-image" />
                        <div className="overlaytxt">
                            <h2>Discover the Future</h2>
                            <p>Explore our latest collection now.</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-content">
                        <img src={m} alt="Slide 2" className="slide-image" />
                        <div className="overlaytxt">
                            <h2>Unleash Your Style</h2>
                            <p>Find the perfect match for your taste.</p>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
// import React, { useEffect, useState } from 'react'
// import { Routes, Route, Navigate, useNavigate  , useLocation} from "react-router-dom"
// import { getCookie } from '../hooks/getcookie'
// import Home from '../pages/Home'
// import Entryform from '../pages/Entryform'
// import Nav from './Nav'
// import Titleboard from './Titlebroad'
// function Switching() {
//     const navigate = useNavigate(); // React router's hook for programmatic navigation
//     const [getenter, setGetEnter] = useState(getCookie("Status")); // Initialize state with the cookie value
//     const location = useLocation()
//     useEffect(() => {
//         // Check cookie periodically using setInterval
//         const interval = setInterval(() => {
//             const cookieValue = getCookie("Status");
//             setGetEnter(cookieValue); // Update the state with the new cookie value
//             if (!cookieValue) {
//                 navigate('/passcode'); // Navigate to /passcode if cookie is null
//             }
//         }, 1000); // Check every second (1000 ms)

//         // Clean up the interval when the component unmounts
//         return () => clearInterval(interval);
//     }, [navigate]);

//     return (
//         <div>
//             {console.log(getenter)}
//             {location.pathname !== "/passcode" && <Titleboard text="XPHENIX * XPHENIX "/>}
//             {location.pathname !== "/passcode" && <Nav/>}
//             <Routes>
//                 <Route path='/' element={getenter ? <Home /> : <Navigate to="/passcode" />} />
//                 <Route path='/passcode' element={!getenter ? <Entryform /> : <Navigate to="/" />} />
//             </Routes>
//         </div>
//     )
// }

// export default Switching;
// 
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { getCookie } from '../hooks/getcookie';
import Home from '../pages/Home';
import Entryform from '../pages/Entryform';
import Nav from './Nav';
import Titleboard from './Titlebroad';

function Switching() {
    const navigate = useNavigate();
    const [getenter, setGetEnter] = useState(null);
    const location = useLocation();

    useEffect(() => {
        // Check cookie on mount
        const cookieValue = getCookie('Status');
        setGetEnter(cookieValue);
        console.log(cookieValue)
        // Redirect if cookie is missing
        if (!cookieValue && location.pathname !== '/passcode') {
            navigate('/passcode');
        }
    }, [navigate, location.pathname]);

    return (
        <div>
            {location.pathname !== '/passcode' && <Titleboard text="XPHENIX * XPHENIX " />}
            {location.pathname !== '/passcode' && <Nav />}
            <Routes>
                <Route path='/' element={getenter ? <Home /> : <Navigate to="/passcode" />} />
                <Route path='/passcode' element={!getenter ? <Entryform /> : <Navigate to="/" />} />
            </Routes>
        </div>
    );
}

export default Switching;
